import { defineAsyncComponent } from "vue";
const CheckoutView = defineAsyncComponent(() => import("@/checkout/checkout-view.vue"));

const STEPS = {
  cart: 0,
  shipping: 1,
  payment: 2,
};

const SCROLL_ELS = document.querySelectorAll("html,body") || [];

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

function calcDirection(currentStep, goToStep) {
  return STEPS[currentStep] < STEPS[goToStep] ? "forward" : "reverse";
}

let stepTimeout;

export default {
  components: {
    cartView: CheckoutView,
  },

  computed: {
    stepClass() {
      let stepInto = this.stepInto;
      let currentStep = this.currentStep;

      if (stepInto) {
        let direction = calcDirection(currentStep, stepInto);

        if (stepInto === "cart" && currentStep === "payment") {
          stepInto = "cart-from-payment";
          this.stepsToJump = 2;
        }

        return `go-to-${stepInto}-${direction}`;
      }

      return null;
    },

    stepDelay() {
      return this.stepsToJump ? 300 : 500;
    },
  },

  data() {
    return { cartResponse: null, checkoutInitialized: false, stepInto: null, stepsToJump: 1 };
  },

  methods: {
    onStepOpen(step) {
      this.stepInto = step;

      clearTimeout(stepTimeout);

      stepTimeout = setTimeout(() => {
        this.stepInto = null;
        this.currentStep = step;

        this.scrollToTop();
      }, this.stepDelay);
    },

    scrollToTop() {
      SCROLL_ELS.forEach((item) => {
        item.scrollTop = 0;
      });
    },

    toggleCheckout() {
      this.checkoutInitialized = true;
      this.activePane = "checkout";
      this.currentStep = "cart";
    },

    toggleCheckoutWithResponse(data) {
      this.toggleCheckout();

      if (data) {
        this.cartResponse = data;
      }
    },
  },

  mounted() {
    if (window.CottonBureau && window.CottonBureau.toggleCheckoutOnPageLoad) {
      this.toggleCheckout();
    }
  },
};
